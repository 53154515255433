<template>
	<main class="auth-layout">
		<div class="container">
			<div class="auth-layout__wrapper">
				<div class="auth-layout__logo">
					<Logo :is-link="false"></Logo>
				</div>

				<div class="auth-layout__phone-block">
					<PhoneBlock :phones="contactsStore.getNoAuthContacts"></PhoneBlock>
				</div>

				<div class="auth-layout__form">
					<slot />
				</div>
			</div>
		</div>
	</main>
</template>

<script setup>
	import { useContactsStore } from "~/store/contacts/contacts";

	const contactsStore = useContactsStore();
	await contactsStore.fetchNoAuthContacts();
</script>

<style lang="scss">
	@import "@/assets/scss/layouts/auth-layout/auth-layout";
</style>
